import { HTTP } from '../../axios/axios'

async function sendSurvey (data) {
  return await HTTP.post('/mail/send-survey-form', data)
}

async function createUser (email) {
  return await HTTP.post('/email-survey', { email })
}

export default { sendSurvey, createUser }
