<template>
  <div>
    <label for="language">
      Choose language:
      <select class="p-2 outline-none" v-model="lang" name="language">
        <option value="en">English</option>
        <option value="az">Azerbaijani</option>
      </select>
    </label>
    <form @submit="validate" class="flex flex-wrap p-10">
      <h1 class="text-2xl mb-8">Generate Template</h1>
      <label for="toEmail" class="w-full flex flex-wrap mb-6">
        <span class="w-full"> To Email </span>
        <input
          v-model="formValues.to"
          class="border-b border-gray-400 outline-none px-3 w-72"
          type="email"
          name="toEmail"
        />
      </label>
      <label for="content" class="w-full flex flex-wrap mb-6">
        <span class="w-full"> Content </span>
        <textarea
          v-model="formValues.content"
          class="border-b border-gray-400 py-2 px-3 w-full outline-none bg-gray-50"
          rows="10"
          name="content"
        />
      </label>
      <button
        class="rounded-lg bg-green-400 hover:bg-green-500 p-2 pr-3 cursor-pointer text-white"
        type="submit"
      >
        Generate
      </button>
    </form>
    <div :class="token ? '' : 'hidden'">
      <div class="survey survey--main">
        <div>{{ formValues.content }}</div>
        <img
          src="https://api.azercosmos.az/images/logo-en.png"
          alt="Azercosmos logo"
          class="survey__logo"
        />
        <h2 class="survey__title">
          {{ langText[lang].question }}
        </h2>
        <div class="survey__rate">
          <div class="survey__rating">
            <a
              :href="`https://azercosmos.az/${lang}/rate-us/${token}/?rating=1`"
              class="survey__rating-item"
              name="1"
            >
              1
            </a>
            <a
              :href="`https://azercosmos.az/${lang}/rate-us/${token}/?rating=2`"
              class="survey__rating-item"
              name="2"
            >
              2
            </a>
            <a
              :href="`https://azercosmos.az/${lang}/rate-us/${token}/?rating=3`"
              class="survey__rating-item"
              name="3"
            >
              3
            </a>
            <a
              :href="`https://azercosmos.az/${lang}/rate-us/${token}/?rating=4`"
              class="survey__rating-item"
              name="4"
            >
              4
            </a>
            <a
              :href="`https://azercosmos.az/${lang}/rate-us/${token}/?rating=5`"
              class="survey__rating-item"
              name="5"
            >
              5
            </a>
          </div>
          <div class="survey__rate--text">
            <div>{{ langText[lang].notSatisified }}</div>
            <div>{{ langText[lang].satisfied }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import requests from '../services/requests/mailRequests'
export default {
  name: 'SurveyForm',
  data () {
    return {
      lang: 'en',
      token: '',
      formValues: {
        to: '',
        content: ''
      },
      error: false,
      langText: {
        en: {
          question:
            'On a scale from 1 to 5, how satisfied are you with our services',
          satisfied: 'Satisfied',
          notSatisified: 'Not satisfied'
        },
        az: {
          question:
            'Bizim xidmətlərimiz sizi nə dərəcədə qane edir? 1-dən 5-ə qədər qiymətləndirin.',
          satisfied: 'Çox qane edir',
          notSatisified: 'Qane etmir'
        }
      }
    }
  },
  methods: {
    validate (e) {
      e.preventDefault()
      if (this.formValues.to && this.formValues.content) {
        this.handleSubmit()
      } else {
        this.error = true
      }
    },
    async handleSubmit () {
      await requests
        .createUser(this.formValues.to)
        .then((res) => {
          console.log(res)
          this.token = res.data
        })
        .catch((err) => console.log(err))
    }
  }
}
</script>

<style lang="scss" scoped>
.survey {
  * {
    --tw-ring-inset: none;
    border-color: initial;
  }
  min-height: 50vh;
  width: 450px;
  max-width: 90vw;
  display: block;
  color: #222222;
  font-family: Arial;
  font-size: 16px;
  div:first-child {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .survey__title {
    font-weight: 500;
    text-align: center;
  }
  .survey__logo {
    display: block;
    margin: 0 auto;
    width: 300px;
    max-width: 90vw;
    margin-bottom: 30px;
  }
  .survey__rate {
    margin: 30px auto 0;
    width: 330px;
    max-width: 100%;
    .survey__rating {
      margin: 0 auto;
      border: 1px solid #59bdbb;
      border-radius: 4px;
      width: 100%;
      font-size: 0;
      .survey__rating-item {
        display: inline-block;
        box-sizing: border-box;
        width: 20%;
        padding: 16px 0;
        font-size: 22px;
        font-weight: 600;
        color: #59bdbb;
        background: #59bdbb11;
        text-align: center;
        border-right: 1px solid #59bdbb;
        &:nth-child(5) {
          border-right: none;
        }
      }
    }
    .survey__rate--text {
      margin-top: 12px;
      color: #59bdbb;
      font-size: 0;
      div:first-child {
        width: 50%;
        display: inline-block;
        font-size: 18px;
      }
      div:nth-child(2) {
        width: 50%;
        display: inline-block;
        font-size: 18px;
        text-align: right;
      }
    }
  }
}
</style>
